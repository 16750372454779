import React from 'react';
import InProgress from '../../components/templates/InProgressLayout';

const DeFi: React.FC = () => {

  return (
    // <InProgress />
    <div>
      <iframe src="https://gptmarket.web3devcommunity.com/" width="100%" height="800px" style={{ border: 'none' }}>
        Sorry, your browser does not support inline frames.
    </iframe>
    </div>
  )
}

export default DeFi